import Button from '@atoms/Button/Button';
import Config from '@config';
import variables from '@styles/variables';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import Heading from '@atoms/Heading/Heading';
import Modal from '@organisms/Modal/Modal';

export const StyledCartConflict = styled.div`
  text-align: left;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
  max-height: 100vh;
  // Set negative margin to reduce the top padding in the conflict modal
  margin-top: ${rem(-38)};

  ${Config.MEDIA.BELOW_DESKTOP} {
    height: calc(100% + ${rem(38)});
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-height: calc(100vh - ${rem(16)});
    width: 100%;
  }

  > * {
    padding: ${rem(20)};

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      padding: ${rem(40)};
    }
  }
`;

export const StyledCartConflictModalHeader = styled.div<{ sticky: boolean }>`
  z-index: 1;
  flex: 0 1 auto;
  background-color: ${variables.colors.white};
  padding-bottom: 0;
  padding-top: ${rem(20)};

  ${(props) =>
    props.sticky &&
    css`
      box-shadow: 0 ${rem(-8)} ${rem(28)} 0 ${rgba(variables.colors.lighterBlack, 0.5)};
    `}
`;

export const StyledCartConflictModalHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: ${rem(1)} dashed ${variables.colors.mediumGray};
  padding-bottom: ${rem(10)};

  > h2 {
    margin-right: ${rem(15)};
    margin-bottom: ${rem(4)} !important;
  }
`;

export const StyledCartConflictModalHeaderNoReplacement = styled.div`
  margin-top: ${rem(35)};
  margin-bottom: ${rem(25)};
`;

export const StyledCartConflictModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
`;

export const StyledCartConflictModalFooter = styled.div`
  flex: 0 1 auto;
  box-shadow: 0 ${rem(8)} ${rem(28)} 0 ${rgba(variables.colors.lighterBlack, 0.5)};
  background-color: ${variables.colors.white};
  z-index: 1;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: ${rem(20)} ${rem(40)};
  }
`;

export const StyledCartConflictModalFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCartConflictButton = styled(Button)<{ wide?: boolean; spacingLeft?: boolean }>`
  ${(props) =>
    props.wide &&
    css`
      flex: 1;
    `}

  ${(props) =>
    props.spacingLeft &&
    css`
      margin-left: ${rem(20)};
    `}
`;

export const StyledCartConflictModalReplaceText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${rem(1)} dashed ${variables.colors.mediumGray};
  padding-top: ${rem(20)};
  margin-bottom: ${rem(10)};
`;

export const StyledCartConflictHeaderLabel = styled(Heading)`
  align-self: flex-start;
`;

export const StyledCartConflictModal = styled(Modal)`
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-height: calc(100vh - ${rem(16)});
  }
`;
